import {useCallback, useEffect, useRef, useState} from "react";
import {RoseGold, SilverGold, YellowGold} from "../Images";
import {RingWhite, RingGold, RingRose} from '../Images';
import {PropsFromScreenManager, TableData} from "../../types";
import {propertyMappings, source} from "../Diamond/useDiamond";
import {TooltipDataFetch} from "../Tooltip/TooltipDataFetch";
import {findNearestValue} from "../../Utils/findNearestCaratValue";
import {useMediaQuery} from "react-responsive";
import {PropsFromStartWithSettingRedux} from "./StartWithSetting";
import {getDiamondCode} from "../../Utils/getDiamondCode";

const swatches:{[key:string]:string} = {
    WG: SilverGold,
    RG: RoseGold,
    YG: YellowGold
}
const images:string[]  = [
    RingWhite,
    RingRose,
    RingGold
]
const options = ['Featured','New Arrivals','Best Seller','Price: Low to High', 'Price: High to Low'];
const useStartWithSetting = (props:PropsFromStartWithSettingRedux & PropsFromScreenManager) => {
    const {
        diamond,
        setIsShowFooter,
        setDiamondDetailsLoading,
        setDiamondDetailsSuccess,
        setDiamondDetailsFail,
        settingFilter,
        setFilterMinMaxProperty,
        setStartWithSettingFilterLoading,
        setStartWithSettingFilterFail,
        setStartWithSettingFilterSuccess,
        setResetStartWithSettingFilter,
        setStartWithSettingProductLoading,
        setStartWithSettingProductSuccess,
        setStartWithSettingProductFail,
        setTooltipDataLoading,
        SetTooltipDataSuccess,
        SetTooltipDataError,
        setAfterDiamondSelectOptions,
        instanceData:{screenManager},
        setSwatchColor,
        setExpandedRow,
        setRingOptions,
        setSelectedRingDetails,
        setStartWithSettingFilter,
        setSelectedDiamondLoading,
        setSelectedDiamondFail,
        setFilter
    } = props;

    const [selectedImages, setSelectedImages] = useState<{[key:string | number]:{[key:string]:string}}>({});
    const [sortBy, setSortBy] = useState(options[0]);
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [positionValue, setPositionValue] = useState<any[]>([]);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const isLargeTablet = useMediaQuery({query: "(max-width: 767px)"});
    const scrollRoot = useRef<HTMLDivElement>(null);
    const [showButton, setShowButton] = useState(false);
    const hasEffectRun = useRef(false);
    const currentUrl = window.location.href;
    const match = currentUrl.match(/diamondId=([^&]*)/);
    let diamondId =match?.[1] || '';
    const isMobileLayout = useMediaQuery({ query: "(max-width: 767px)" });

    useEffect(() => {
            document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);
    const DetailsData = useCallback(async (id : string) => {
        setDiamondDetailsLoading();
        try {
            const response = await fetch(`${process.env.REACT_APP_API_KEY}/?id='${id}'`);
            if (!response.ok) {
                setDiamondDetailsFail(new Error(`HTTP error! Status: ${response.status}`));
            }
            const result: TableData = await response.json();

            Object.keys(propertyMappings).forEach((property: string) => {
                result[property] = propertyMappings[property][result[property] as number  - 1];
            });
            const updatedResult: TableData = {
                ...result,
                source: result.source === 'HK-ND' ? source[0] : source[1],
                // diamond_price : result.diamond_price !== null ? `${result.diamond_price}` : '-'
            }
            if(Object.keys(updatedResult).length > 0){
                const {source,carat,shape} = updatedResult;
                const diamondDetail: { [key: string]: string } = {
                    type:source as string,
                    shape: shape as string,
                };
                getDiamondCodeData(diamondDetail,carat as number)
                setDiamondDetailsSuccess(updatedResult);
            }

            setIsShowFooter(true);
        } catch (e) {
            setDiamondDetailsFail(e);
        }
    },[diamondId,setDiamondDetailsSuccess, setDiamondDetailsLoading, setDiamondDetailsFail,fetch]);
    const getDiamondCodeData = useCallback(async(diamondDetail: { [key: string]: string },carat:number) => {
        const result = await getDiamondCode(diamondDetail,setSelectedDiamondLoading,setSelectedDiamondFail);
        const SelectedDiamondData = {
            ...result,
            Center_Diamond_Type: result.Diamond_Type,
            Center_Diamond_Size: findNearestValue(carat as number,true)
        }
        delete SelectedDiamondData.Diamond_Type;
        setAfterDiamondSelectOptions(SelectedDiamondData as {[key:string]:string|string[]});
    },[getDiamondCode,setSelectedDiamondLoading,setSelectedDiamondFail,findNearestValue,setAfterDiamondSelectOptions])
    const afterDiamondSelectOptions = useCallback(async () => {
        if(diamondId && Object.keys(diamond.details).length < 1){
            DetailsData(diamondId as string);
        }else if(Object.keys(diamond.details).length > 0) {
            const {source,shape,carat} = diamond.details;
            const diamondDetail: { [key: string]: string } = {
                type:source as string,
                shape: shape as string,
            };
            getDiamondCodeData(diamondDetail,carat as number)
        }
    },[diamondId,diamond.details,DetailsData,getDiamondCodeData])
    useEffect(()=>{
        afterDiamondSelectOptions();
    },[])

    useEffect(()=>{
        const newSelectedImages : {[key:string | number]:{[key:string]:string}} = {};
        settingFilter.products.forEach(product => {
            newSelectedImages[product.Code] = {
                imagePath: product.images[settingFilter.options["Color"] as string] || product.images["DF"],
                swatch: settingFilter.options["Color"] as string
            }
        })
        setSelectedImages(newSelectedImages)
    },[settingFilter.options["Color"],settingFilter.products])

    const handleSwatchClick = useCallback((productId: number,image:string,key:string) => {
        setSelectedImages(prevState => ({
            ...prevState,
            [productId]: {
                imagePath: image,
                swatch : key
            }
        }));
    },[setSelectedImages]);

    const getSettingOptions = useCallback(async () => {
        try {
            setStartWithSettingFilterLoading();
            const response = await fetch(`${process.env.REACT_APP_SETTING_OPTIONS}`);
            if (!response.ok) {
                setStartWithSettingFilterFail(new Error(`HTTP error! Status: ${response.status}`));
            }
            const result = await response.json();
            setStartWithSettingFilterSuccess(result);
            // Update state with the data
        } catch (error: unknown) {
            setStartWithSettingFilterFail(error);
        }
    }, [fetch,setStartWithSettingFilterLoading,setStartWithSettingFilterFail,setStartWithSettingFilterSuccess]);

    const handleDropdownToggle = useCallback(() => {
        setIsDropdownOpen((dropDown)=>!dropDown);
    },[setIsDropdownOpen])

    const handleOptionClick = useCallback((option:string) => {
        setSortBy(option);
        setIsDropdownOpen(false);
    },[setSortBy,setIsDropdownOpen]);

    const handleClickOutside = useCallback((event:any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsDropdownOpen(false);
        }
    },[setIsDropdownOpen,dropdownRef.current]);

    const handleRESETFilters = useCallback(() => {
        setSortBy(options[1]);
        setResetStartWithSettingFilter();
    },[setResetStartWithSettingFilter,setSortBy,options])

    const getSettingProducts = useCallback(async () => {
        const queryParams = new URLSearchParams({...settingFilter.options,'sort_by':sortBy.toLowerCase().replaceAll(' ','_')} as Record<string, string>).toString();
        try {
            setStartWithSettingProductLoading();
            const response = await fetch(`${process.env.REACT_APP_START_WITH_SETTING}?${queryParams}`);
            if (!response.ok) {
                setStartWithSettingFilterFail(new Error(`HTTP error! Status: ${response.status}`));
            }
            const result = await response.json();
            setStartWithSettingProductSuccess(result);
            const prices = result.map((product:any) => product.price_at);
            const minValue = (prices.length > 0) ? Math.min(...prices) : 0;
            const maxValue = (prices.length > 0) ? Math.max(...prices) : 0;
            if(!Object.keys(settingFilter.options).includes("Setting_Price")){
                setFilterMinMaxProperty({
                    Setting_Price: {min : minValue, max: maxValue}
                })
            }

            setPositionValue(result);
        } catch (error: unknown) {
            setStartWithSettingProductFail(error);
        }
    }, [fetch,setStartWithSettingProductLoading,setStartWithSettingProductSuccess,setStartWithSettingProductFail,settingFilter.options,setPositionValue,setFilterMinMaxProperty,sortBy]);

    useEffect(() => {
        const { options, isReset } = settingFilter;
        if (['Price: Low to High', 'Price: High to Low'].includes(sortBy)) return;
        if (Object.keys(options).length > 0 || isReset) {
            getSettingProducts();
            return;
        }
        if (!diamondId) {
            const defaultSettings = {
                Center_Diamond_Size: '200',
                Metal: '14'
            };
            Object.entries(defaultSettings).forEach(([key, value]) => {
                setStartWithSettingFilter(key, value);
            });
        }
    }, [settingFilter.options, sortBy, settingFilter.isReset]);


    useEffect(() => {
        getSettingOptions();
    }, []);

    useEffect(()=>{
        const sortingProducts = settingFilter.products;
        if(sortBy === 'Price: Low to High'){
            setStartWithSettingProductLoading();
            sortingProducts.sort((a,b) => a.price_at - b.price_at);
            setStartWithSettingProductSuccess(sortingProducts)
        }else if(sortBy ==='Price: High to Low') {
            setStartWithSettingProductLoading();
            sortingProducts.sort((a,b) => b.price_at - a.price_at);
            setStartWithSettingProductSuccess(sortingProducts)
        }
    },[sortBy])

    useEffect(()=>{
        if(!hasEffectRun.current){
            TooltipDataFetch(setTooltipDataLoading,SetTooltipDataSuccess,SetTooltipDataError);
        }
        return () => {
            hasEffectRun.current = true;
        }
    },[hasEffectRun])

    const handleProduct = useCallback((code:string,product:{[key:string]:string|{[key:string]:string}}) => {
        const selectedSwatchValue = selectedImages[code].swatch;
        setSwatchColor(selectedSwatchValue)
        if(Object.keys(product).length > 0){
            Object.entries(product.default_options).forEach(([key,value]) => setStartWithSettingFilter(key,value));
        }
        if(Object.keys(diamond.details).length >0){
            (screenManager as any).changeScreen({viewName: 'dyo',styleId:code ,id:diamond.details.id})
        }else {
            (screenManager as any).changeScreen({viewName: 'dyo',styleId:code})
        }
    },[screenManager,diamond.details,selectedImages])

    const handleScroll = useCallback(() => {
        const div = scrollRoot.current;
        const threshold = 300;
        if (div) {
            const scrollY = div.scrollTop;
            setShowButton(scrollY > threshold);
        }
    },[setShowButton,scrollRoot.current]);

    useEffect(() => {
        const div = scrollRoot.current;
        if (div) {
            div.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (div) {
                div.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    const handleScrollTopClick = useCallback(() => {
        if (scrollRoot.current) {
            scrollRoot.current.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }
    },[scrollRoot.current]);

    return {
        dropdownRef,
        sortBy,
        isDropdownOpen,
        options,
        selectedImages,
        handleSwatchClick,
        handleDropdownToggle,
        handleRESETFilters,
        handleOptionClick,
        swatches,
        diamondId,
        isMobileLayout,
        handleProduct,
        handleScrollTopClick,
        isLargeTablet,
        showButton,
        scrollRoot
    }
}
export default useStartWithSetting;
