import React, { Component } from 'react';
import { RootState } from './reducers/RootReducer';
import { setStateManagerData,setStateEditData } from './action/StateManagerAction';
import { setDiamondDetailsSuccess } from './action/DiamondAction';
import { setExpandedRow } from './action/TableAction';
import { setRingOptions, setSelectedRingDetails,setRingPrice,setRingExtraData } from './action/RingAction';
import { setIsShowFooter, setIsShowCartPage,setStyleID } from './action/FooterAction';
import { setFilter } from './action/FilterAction';
import { connect, ConnectedProps } from 'react-redux';
import RouteManager from './RouteManager';
import Loader from "./components/Loader/Loader";
import ComponentManger from "./ComponentManger";
import {setStartWithSettingFilter,setFilterMinMaxProperty} from './action/StartWithSettingFilterAction';
import {setDiamondAvailable} from './action/DiamondAction';

const mapStateToProps = (state: RootState) => ({
  stateManagerData: state.stateManager,
  diamond:state.diamond,
  footer:state.footer,
});

const mapDispatchToProps = {
  setStateManagerData,
  setDiamondDetailsSuccess,
  setExpandedRow,
  setRingOptions,
  setSelectedRingDetails,
  setFilter,
  setIsShowFooter,
  setIsShowCartPage,
  setStartWithSettingFilter,
  setStyleID,
  setFilterMinMaxProperty,
  setRingPrice,
  setStateEditData,
  setDiamondAvailable,
  setRingExtraData
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromStateManagerRedux = ConnectedProps<typeof connector>;
type PropsFromComponent = {
  data: {
    app: any;
  };
  changeScreen?: (data: { viewName: string; id: string }) => void;
};

interface ScreenManagerState {
  instanceData: {
    screenManager: ScreenManager;
  };
}

class ScreenManager extends Component<PropsFromStateManagerRedux & PropsFromComponent, ScreenManagerState> {

  constructor(props: PropsFromStateManagerRedux & PropsFromComponent) {
    super(props);
    this.state = {
      instanceData: { screenManager: this },
    };
  }

  urlUpdate(url: string): void {
    window.history.pushState({}, '', `#${url}`);
  }

  changeScreen = (data: { viewName: string | undefined;styleId?:string|null, id?: string; uuid?: string }, type?: string | undefined): void => {
    let fullPath;
    switch (data.viewName){
      case 'build':
        fullPath = 'home';
        // localStorage.setItem('rpid', JSON.stringify(data.id));
        break;
      case 'edit':
        fullPath = 'summary';
        // localStorage.setItem('rpid', JSON.stringify(data.id));
        break;
      case 'dyo':
      case 'setting':
        fullPath = `${data.viewName}${data.styleId?`/styleId=${data.styleId}`:''}${data.id? `/diamondId=${data.id}`:''}`;
        break;
      case 'details':
        fullPath = `${data.viewName}/diamondId=${data.id}`;
        break;
      // case 'setting':
      //   fullPath = `${data.viewName}${data.styleId?`/styleId=${data.styleId}`:''}${data.id? `/diamondId=${data.id}`:''}`;
      //   break;
      default:
        fullPath = data.viewName;
    }
    if(type !== 'popstate'){
      
      this.urlUpdate(fullPath as string);
    }
    const path = RouteManager.findPath(`#${data.viewName}`);

    const managerData = {
      path,
      id: data.id || ''
    }
    Object.entries(managerData).forEach(([key,value]) => this.props.setStateManagerData(key,value));
  };

  componentDidMount(): void {
    this.handleUrlChange();
    window.addEventListener('popstate', this.handleUrlChange);
  }

  componentWillUnmount(): void {
    window.removeEventListener('popstate', this.handleUrlChange);
  }

  shouldComponentUpdate(prevProps: PropsFromStateManagerRedux): boolean {
    return prevProps.stateManagerData.options.path !== this.props.stateManagerData.options.path;
  }

  parseURL(url: string): { command: string; params: { [key: string]: string } } {
    const fragment = url?.split('#')[1];
    if (!fragment) {
      this.changeScreen({viewName:'home'});
      // throw new Error('Invalid URL format: Missing fragment identifier');
    }
    const parts = fragment?.split('/');
    const command = parts?.find(part => part) || '';
    if (parts && parts.length > 1) {
      const queryParts = parts.slice(1).join('&')?.split('&');
      const params: { [key: string]: string } = {};
      for (const queryPart of queryParts) {
        const [key, value] = queryPart?.split('=');
        params[key] = value;
      }
      return { command, params };
    } else {
      return { command, params: {} };
    }
  }
  getSKU = async (uuid:string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_GET_DESIGN_DATA}?hkuuid=${uuid}`);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      const {left_band_retail_price,right_band_retail_price,ring_retail_price} =result.pricing_data;
      if(result){
        this.props.setStateEditData(result);
        result?.inventoryAvailabilities && this.props.setDiamondDetailsSuccess(result?.diamond_details);
        // this.props.setExpandedRow(0,result?.diamond_details.id as string);
        this.props.setIsShowFooter(true);
        this.props.setStyleID(result.sku.split('-')[0]);
        this.props.setRingPrice({ring_price:ring_retail_price, left_band_price:left_band_retail_price, right_band_price:right_band_retail_price});
        this.props.setRingExtraData(result.ring_extra_data);
        if(Object.keys(result.setting_filter_options).length >0 && Object.keys(result.setting_filter_options).includes('Setting_Price')){
          const settingPrice = result.setting_filter_options.Setting_Price.split('-');
          this.props.setFilterMinMaxProperty({
            Setting_Price: {min : +settingPrice[0], max: +settingPrice[1]}
          })
        }
        Object.entries(result.ring_options_code).forEach(([key,value])=> this.props.setRingOptions(key,value as string));
        Object.entries(result?.setting_details).forEach(([key,value])=> this.props.setSelectedRingDetails(key,value as string));
        Object.entries(result?.diamond_filter_options).forEach(([key,value])=> this.props.setFilter(key,value as string));
        Object.entries(result?.setting_filter_options).forEach(([key,value])=> this.props.setStartWithSettingFilter(key,value as string));
        Object.entries(result?.table_row_id).forEach(([key,value])=> this.props.setExpandedRow(+key,value as string));
        this.props.setDiamondAvailable(result?.inventoryAvailabilities);
        return result;

      }
    }catch (e) {
      console.log(e)
    }

  }
  handleUrlChange = async(e?: PopStateEvent): Promise<void> => {
    try {
      const parsedData = this.parseURL(window.location.href as string);
      const { command, params } = parsedData;
      let { start_with, rpid, hkuuid: uuid,diamondId:id,styleId } = params;
      const viewName = start_with && ['diamond', 'setting','dyo'].includes(start_with.toLowerCase())
        ? start_with.toLowerCase()
        : command;
      styleId = start_with && start_with.toLowerCase()==='dyo'?'RB001': styleId;
      if(["build","edit"].includes(command)){
        ['rpid','builder_mode','design_uuid'].forEach(key =>localStorage.removeItem(key));
      }
      if(uuid || JSON.parse(localStorage.getItem('design_uuid') as string)){
        //@ts-ignore
        const result = await this.getSKU(uuid || JSON.parse(localStorage.getItem('design_uuid')));
        styleId = result?.sku?.split('-')[0];
        id = result.diamond_details.id;
      }
      if(command === "build"){
          this.changeScreen({ viewName, uuid ,id: id,styleId}, '');
        this.props.setIsShowFooter(false);
      } else if(["edit","summary"].includes(command)){
        if(this.props.diamond.isDiamondAvailable){
          this.props.setIsShowCartPage();
          this.props.setIsShowFooter(true);
          this.changeScreen({ viewName: 'edit' });
        } else {
          this.changeScreen({ viewName:'diamond'});
        }
      } else {
        this.changeScreen({ viewName, id: id, uuid ,styleId}, e?.type);
      }
      const sessionStorageObject = {
        'rpid': rpid ,
        'builder_mode': command,
        'design_uuid': uuid
      }
      if(['build','edit'].includes(command)) {
        Object.entries(sessionStorageObject).forEach(([key, value]) => {
          if (value) {
            localStorage.setItem(key, JSON.stringify(value));
          } else {
            localStorage.removeItem(key);
          }
        })
      }/*else{
        Object.entries(sessionStorageObject).forEach(([key, value]) => (
            localStorage.removeItem(key)
        ))
      }*/
    } catch (error) {
      console.error('Error parsing URL:', error);
    }
  };

  render(): JSX.Element {
    const { stateManagerData: { options: { path } } } = this.props;
    let ViewComponent: any | null = null;
    if (path) {
      ViewComponent = ComponentManger.findComponent(path as string);
    }

    return (
        <React.Suspense fallback={<div style={{height : '100vh'}}><Loader /></div>}>
        {ViewComponent && (
          //@ts-ignore
          <ViewComponent instanceData={this.state.instanceData} />
        )}
      </React.Suspense>
    );
  }
}

export default connector(ScreenManager);
