import {RingAction, RingState} from "../types";
import RingConstant from "../constant/RingConstant";

const initialRingState: RingState = {
    isLoading : false,
    isSelectedDiamondLoading:false,
    selectedDiamondError:'',
    error: '',
    optionsData: {},
    options: {},
    selectedRingDetails: {},
    selectedDiamondOptions: {},
    isBackToDiamondSelection: false,
    tabIndex:0,
    journeyStartedFrom:'',
    ringPrice: {},
    isPriceLoading: false,
    priceError: '',
    extraData: {}
};

const ringReducer = (state = initialRingState, action: RingAction): RingState => {
    switch (action.type) {
        case RingConstant.RING_OPTIONS_LOADING :
            return {
                ...state ,
                isLoading: true
            }
        case RingConstant.RING_OPTIONS_DATA:
            return {
                ...state,
                isLoading: false,
                error : '',
                optionsData : action.payload as any,
            };
        case RingConstant.RING_OPTIONS_FAIL:
            return {
                ...state,
                isLoading: false,
                optionsData : {},
                error : action.payload as (Error | string)
            };
        case RingConstant.RING_OPTIONS_DEFAULT:
            return {
                ...state,
                isLoading: false,
                options:{},
                error:''
            }
        case RingConstant.RING_SELECTED_OPTIONS:
            return {
                ...state,
                options : {
                    ...state.options,
                    [(action.payload as { key: string; value: string; }).key]: (action.payload as { key: string; value: string; }).value,
                }
            };
        case RingConstant.SET_SELECTED_DIAMOND_LOADING :
            return {
                ...state ,
                isSelectedDiamondLoading: true
            }
        case RingConstant.SET_SELECTED_DIAMOND_DATA:
            return {
                ...state,
                isSelectedDiamondLoading: false,
                selectedDiamondError : '',
                options : {
                    ...state.options,
                    ...action.payload
                },
                selectedDiamondOptions: {
                    ...action.payload
                }
            };
        case RingConstant.SET_SELECTED_DIAMOND_FAIL:
            return {
                ...state,
                isSelectedDiamondLoading: false,
                selectedDiamondOptions : {},
                selectedDiamondError : action.payload as (Error | string)
            };
        case RingConstant.SELECTED_RING_DETAILS:
            return {
                ...state,
                selectedRingDetails : {
                    ...state.selectedRingDetails,
                    [(action.payload as { key: string; value: string; }).key]: (action.payload as { key: string; value: string; }).value,
                }
            };
        case RingConstant.SET_INITIAL_RING_OPTIONS:
            return {
                ...initialRingState,
                journeyStartedFrom:state.journeyStartedFrom
            };
        case RingConstant.BACK_DIAMOND_SELECTION_OPTIONS:
            return {
                ...state,
                isBackToDiamondSelection : action.payload
            };
        case RingConstant.SET_REMOVE_RING_OPTION:
            delete state.options[action.payload]
            return {
                ...state
            }
        case RingConstant.SET_BAND_TAB_INDEX:
            return {
                ...state,
                tabIndex : action.payload
            }
        case RingConstant.SET_JOURNEY_STARTED_FROM:
            return {
                ...state,
                journeyStartedFrom:action.payload
            }
        case RingConstant.SET_RING_PRICE_LOADING:
            return {
                ...state,
                isPriceLoading: true,
                ringPrice: {}
            }
        case RingConstant.SET_RING_PRICE:
            return {
                ...state,
                isPriceLoading: false,
                priceError: '',
                ringPrice: {...action.payload}
            }
        case RingConstant.SET_RING_PRICE_ERROR:
            return {
                ...state,
                isPriceLoading: false,
                ringPrice : {},
                priceError : action.payload as (Error | string)
            }
        case RingConstant.RING_EXTRA_DATA:
            return {
                ...state,
                extraData : action.payload as any
            }
        default:
            return state;
    }
};

export default ringReducer;
