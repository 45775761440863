import StartWithSettingFilterConstant from "../constant/StartWithSettingFilterConstant";
import {StartWithSettingFilterAction, StartWithSettingFilterState} from "../types";

const initialStartWithSettingFilterState: StartWithSettingFilterState = {
    options: {
        // Center_Diamond_Size:'200',
        // Metal:'14'
    },
    optionsData : {},
    isLoading: false,
    products: [],
    isProductLoading: false,
    isProductError: '',
    error : '',
    isReset:false,
    isMinMaxChangeValue:true,
    afterDiamondSelectedOptions :{},
    minMaxValue: {},
    swatchColor: ''
};

const startWithSettingFilterReducer = (state = initialStartWithSettingFilterState, action: StartWithSettingFilterAction) => {
    switch (action.type) {
        case StartWithSettingFilterConstant.START_WITH_SETTING_FILTER_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case StartWithSettingFilterConstant.START_WITH_SETTING_FILTER_DATA_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: '',
                optionsData: action.payload as string | string[]
            };
        case StartWithSettingFilterConstant.START_WITH_SETTING_FILTER_FAIL:
            return {
                ...state,
                isLoading: false,
                optionsData: [],
                error: action.payload as (Error | string)
            }
        case StartWithSettingFilterConstant.SET_START_WITH_SETTING_FILTER :
            return {
                ...state,
                isReset:false,
                options: {
                    ...state.options,
                    [action.payload.key]: action.payload.value,
                }
            }
        case StartWithSettingFilterConstant.SET_RESET_START_WITH_SETTING_FILTER :
            return {
                ...state,
                isReset:true,
                options : {
                    Center_Diamond_Size:'200',
                    Metal:'14',
                    ...state.afterDiamondSelectedOptions
                }
            };
        case StartWithSettingFilterConstant.START_WITH_SETTING_PRODUCT_LOADING:
            return {
                ...state,
                isProductLoading: true,
            }
        case StartWithSettingFilterConstant.START_WITH_SETTING_PRODUCT_SUCCESS:
            return {
                ...state,
                isProductLoading: false,
                isProductError: '',
                products: action.payload as any[]
            };
        case StartWithSettingFilterConstant.START_WITH_SETTING_PRODUCT_FAIL:
            return {
                ...state,
                isProductLoading: false,
                products: [],
                isProductError: action.payload as (Error | string)
            }
        case StartWithSettingFilterConstant.START_WITH_SETTING_MIN_MAX_VALUE:
            return {
                ...state,
                minMaxValue : (action.payload as {[key: string]: {[key:string] : number}})
            }
        case StartWithSettingFilterConstant.REMOVE_FILTER_KEY:
            delete state.options[(action.payload as { key: string; value: string| string[] }).key]
            return {
                ...state,
                options: {...state.options},
                isReset:false
            };
        case StartWithSettingFilterConstant.SET_START_WITH_SETTING_MIN_MAX_VALUE_CHANGE:
            return {
                ...state,
                isMinMaxChangeValue : action.payload as boolean
            };
        case StartWithSettingFilterConstant.SET_AFTER_DIAMOND_SELECTED_OPTIONS :
            return {
                ...state,
                afterDiamondSelectedOptions : action.payload as {[key:string] : string | string[]},
                options : {...state.options , ...action.payload as {[key:string] : string | string[]}}
            }
        case StartWithSettingFilterConstant.SET_SWATCH_COLOR :
            return {
                ...state,
                swatchColor : action.payload
            }
        case StartWithSettingFilterConstant.SET_INITIAL_START_WITH_SETTING:
            return initialStartWithSettingFilterState;
        default:
            return state;
    }

}
export default startWithSettingFilterReducer;
