import {useCallback, useEffect, useRef, useState} from "react";
import {DiamondSizeSliderType} from "../../types";

const useDiamondSizeSlider = ({marks,fIndex,allValue,handleAllOptions,shapeKey,isStartWithSetting,isReset} :DiamondSizeSliderType) => {
    const [value, setValue] = useState<[number]>([+Object.keys(marks)[fIndex]]);
    const handleChange = useCallback((val: number | number[]): void => {
        if (Array.isArray(val)) {
            setValue(val as [number]);
        }
    }, [setValue]);
    const pRef = useRef(null);
    useEffect(() => {
        const diamondSizeMap:{[key:string]:number} = {
            "075": 0,
            "100": 25,
            "150": 50,
            "200": 75,
            "300": 100,
        };
        (pRef.current as any).addEventListener('setDiamondSize', (e:any)=>{
            setValue([diamondSizeMap[e.detail.size]] as [number]);
            //@ts-ignore
            handleAllOptions('Center_Diamond_Size',{Code:e.detail.size})
            console.log('react caught custom event directly on component',e.detail);
        });
    },[]);
    const handleLabelChangeComplete = useCallback((val: number | number[]) => {
        if (Array.isArray(val)) {
           /* const fromVal = Object.keys(marks)
                .sort((a, b) => (b > a ? -1 : 1))
                .indexOf(val[0].toString());*/
        const markVal = Object.entries(marks).find(([key, v]) => key.toString() === val[0].toString());
            if (markVal) {
                const shapeValue = allValue.find(v => v.Name === markVal[1]);
                if(shapeValue){
                    if(isStartWithSetting){
                        //@ts-ignore
                        handleAllOptions(shapeValue.Code,shapeKey)
                    }else {
                        //@ts-ignore
                        handleAllOptions(shapeKey, shapeValue)
                    }

                }
            }
        }

    }, [marks]);
    useEffect(()=>{
        if(isReset){
            setValue([+Object.keys(marks)[fIndex]]);
        }
    },[isReset])

    return {
        value,
        pRef,
        handleChange,
        handleLabelChangeComplete
    }
}
export default useDiamondSizeSlider;
