import React, {useEffect, useRef} from "react";
import Slider from "react-slick";
import classes from "./Slider.module.css";
import { SlickSliderProps } from "../../types";
import Icon from "../Icons/Icon";
import Vector from "../Vector";

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 5,
    responsive: [
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 550,
            settings: {
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 425,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 350,
            settings: {
                slidesToShow: 3,
            },
        },
    ],
};
const settingsType1 = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 5,
    responsive: [
        {
            breakpoint: 2100,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 1366,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 875,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 6,
            },
        },
        {
            breakpoint: 610,
            settings: {
                slidesToShow: 5,
            },
        },
        {
            breakpoint: 525,
            settings: {
                slidesToShow: 4,
            },
        },
        {
            breakpoint: 420,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 340,
            settings: {
                slidesToShow: 2,
            },
        },
    ],
};

const SlickSlider: React.FC<SlickSliderProps> = (props) => {
  const { contents, handleFilterType, filter,removeFilterKey,labelKey,isMultiSelect ,setChangeMinMaxValue,isNotUpperCase, alterSlideConfig,isSingleEnable} = props;
  const SlickButtonFix = (props: {
    children: JSX.Element;
    slideCount?: number;
    currentSlide?: number;
  }) => {
    const { children, currentSlide, slideCount, ...others } = props;
    return <div {...others}>{children}</div>;
  };
    const sliderSettings = alterSlideConfig ? { ...settingsType1 } : { ...settings };
    const slickRef = useRef<Slider>(null);

    useEffect(() => {
        const findInitialSlideIndex = () => {
            const currentSlideElement = document.querySelector('.slick-slide .currentActiveSlide');
            const parentSlideElement = currentSlideElement?.closest('.slick-slide');
            const dataIndex = parentSlideElement?.getAttribute('data-index');
            const index = dataIndex ? parseInt(dataIndex, 10) : undefined;
            slickRef.current?.slickGoTo(index ?? 0);
        };
        findInitialSlideIndex();
    }, [slickRef]);


    const handleShape = (findFilterShape: string | boolean | string[] | undefined , label : string) => {
      if(setChangeMinMaxValue){
          setChangeMinMaxValue(true);
      }
      if(isMultiSelect){
          if(Array.isArray(filter[labelKey]) && findFilterShape){
              const filterShape = (filter[labelKey] as string[]).filter(f => f !== label);
              if(filterShape.length === 0){
                  return removeFilterKey(labelKey);
              }
              return handleFilterType(filterShape, labelKey)
          }
          if(filter[labelKey]){
              return handleFilterType([...filter[labelKey] as string | string[],label], labelKey)
          }else {
              return handleFilterType([label], labelKey)
          }
      }
      if(!isSingleEnable){
          if(findFilterShape){
              const filterShape = filter[labelKey];
              if(filterShape === label){
                  return removeFilterKey(labelKey);
              }
              return handleFilterType(label, labelKey)
          }
      }
      handleFilterType(label, labelKey);
  }
  return (
    <div className={classes.root}>

      <Slider
          {...sliderSettings}
          prevArrow={
          <SlickButtonFix>
            <Icon name="hk_chevron_left" className={classes.prevArrow} />
          </SlickButtonFix>
        }
        nextArrow={
          <SlickButtonFix>
            <Icon name="hk_chevron_right" className={classes.nextArrow} />
          </SlickButtonFix>
        }
      ref={slickRef}
      >
        {contents &&
          contents.map((content, index: number) => {
            const findFilterShape = isMultiSelect ? Array.isArray(filter[labelKey]) && (filter[labelKey] as string[]).find(f => f === content.code.toUpperCase()) : Array.isArray(filter[labelKey]) ? (filter[labelKey] as string[])[0] :filter[labelKey];
            return (
              <div
                className={
                    `${findFilterShape === (isNotUpperCase ? content.code : content.code.toUpperCase())
                        ? `${classes.active} currentActiveSlide`
                        : ''} ${classes.content}`
                }
                key={index}
                onClick={() => handleShape(findFilterShape,isNotUpperCase ? content.code : content.code.toUpperCase())}
              >
                <div className={classes.img}>
                    {/*<img src={content.imgPath}/>*/}
                  <Vector name={content.label?.toLowerCase()} />
                </div>

                <div className={classes.label}>{content.label}</div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default SlickSlider;
