import React, {FC, Fragment, useCallback} from 'react';
import Label from '../Label/Label';
import Button from '../Button';
import usePdpBuilder from './useProductBuilder';
import {RootState} from "../../reducers/RootReducer";
import {connect, ConnectedProps} from "react-redux";
import {setRingOptions,setOptionsLoading,setOptionsData,setOptionsFail, setSelectedDiamondLoading,setBandTabIndex,setRingPrice,setPriceLoading,setRingPriceError,
    setSelectedDiamondData, setSelectedDiamondFail,setSelectedRingDetails,setInitialOption,setBackDiamondDetails,setRemoveRingOption,setRingExtraData} from '../../action/RingAction'
import {setFooterToggle,setIsShowFooter,setStyleID,setRedirectEditPage} from '../../action/FooterAction';
import {setFilter,setAfterRingOptions} from '../../action/FilterAction';
import {setDiamondDetailsLoading,setDiamondDetailsFail,setDiamondDetailsSuccess} from '../../action/DiamondAction';
import {set3DImageLoading,set3DImageSuccess} from '../../action/CartAction';
import Loader from '../Loader/Loader';
import DiamondSizeSlider from "../DiamondSizeSlider";
import {centerDiamondSizeType, DiamondSizeValueType, PDPBuilderType, PropsFromScreenManager} from "../../types";
import FloatingLabel from "../FloatingLabel/floatingLabel";
import { setHeaderTabDefault, setSettingTabIconTrue} from '../../action/HeaderAction';
import {SetTooltipDataSuccess,setTooltipDataLoading,SetTooltipDataError} from "../../action/TooltipAction";
import classes from './PdpBuilder.module.css';
import {ToLocalStringConvert} from "../../Utils/toLocalStringConvert";
import {ColorImg, DiamondShapeContent} from "../../Utils/commonData";
import {setExpandedRow} from '../../action/TableAction';
import Icon from "../Icons";


const mapStateToProps = (state: RootState) => ({
    ring: state.ring,
    diamond: state.diamond,
    header :state.header,
    tooltip:state.tooltip,
    stateManager: state.stateManager,
    footer:state.footer,
    settingFilter:state.settingFilter,
    table:state.table,
    filter:state.filter
});

const mapDispatchToProps = {
    setRingOptions,
    setOptionsLoading,
    setOptionsData,
    setOptionsFail,
    setSelectedDiamondLoading,
    setSelectedDiamondData,
    setSelectedDiamondFail,
    setSelectedRingDetails,
    setFooterToggle,
    setFilter,
    setDiamondDetailsLoading,
    setDiamondDetailsFail,
    setDiamondDetailsSuccess,
    setInitialOption,
    setBackDiamondDetails,
    setSettingTabIconTrue,
    setHeaderTabDefault,
    setAfterRingOptions,
    setRemoveRingOption,
    setBandTabIndex,
    SetTooltipDataSuccess,
    setTooltipDataLoading,
    SetTooltipDataError,
    setIsShowFooter,
    setStyleID,
    setRedirectEditPage,
    setPriceLoading,
    setRingPrice,
    setRingPriceError,
    setExpandedRow,
    set3DImageLoading,
    set3DImageSuccess,
    setRingExtraData
};
const connector = connect(mapStateToProps, mapDispatchToProps);

export type PropsFromRingRedux = ConnectedProps<typeof connector>;

const PdpBuilder: FC<PDPBuilderType & PropsFromScreenManager> = (props) => {
    const {
        ring,
        diamond,
        footer,
        settingFilter,
        setBandTabIndex,
        setBackDiamondDetails,
        setHeaderTabDefault,
        setRemoveRingOption,
        setRingOptions,
        setRedirectEditPage,
        setInitialOption,
        setIsShowFooter,
        instanceData: { screenManager }
    }  = props;

    const {
        productName,
        handleAllOptions,
        styleId,
        handleSelectSetting,
        fieldValidation,
        isEditableRingOptions,
        rpid,
        uuid,
        builder_mode,
        weddingBand,
        ringPrice,
        setShowError
    } = usePdpBuilder(props);

    if(ring.isLoading || diamond.isLoading || ring.isSelectedDiamondLoading){
       return <div className={'loading-details'}><Loader position='absolute' /></div>
    }
    if(ring.error){
        return <div>Error</div>
    }

    const handleWeddingBand = (number: number,label: string) => {
        setShowError(false);
        isEditableRingOptions.current = false;
        setBandTabIndex(number);
        setRedirectEditPage(false);
        setRingOptions("Wedding_Band",label);
        ["Single_First_Band", "Double_First_Band", "Double_Second_Band"]
            .filter((band, index) => number === 0 || (number === 1 && index > 0) || (number === 2 && index < 1))
            .forEach(band => setRemoveRingOption(band));
        ["First_Band_Engraving", "Second_Band_Engraving"]
            .filter((band, index) => number === 0 || (number === 1 && index > 0) || (number === 2 && index > 1))
            .forEach(band => setRemoveRingOption(band));
        Array.from(Array(number)).map((tabIndex,index)=>{
            setRingOptions(`${label}_${index + 1 === 1 ? 'First' : 'Second'}_Band`,ring.options["Color"]);
        })
    }

    const weddingButton = [
        {
            Name : "White",
            Code:"WG"
        },
        {
            Name : "Yellow",
            Code:"YG"
        },
        {
            Name : "Rose",
            Code:"RG"
        }
    ];

    const handleMatchingBand = (index: number,Code : string) => {
        setShowError(false);
        isEditableRingOptions.current = false;
        window.postMessage({
          action : index === 0 ? 'ChangeLeftBandColor' : 'ChangeRightBandColor',
          value: Code
        })
        setRingOptions(index === 0 ? `${ring.options["Wedding_Band"]}_First_Band`:`${ring.options["Wedding_Band"]}_Second_Band`,Code)
    }

    return (
    <>
        <div className={classes.root}>
            <div className={classes.stickys}>
                <div className={classes.title_wrap}>
                    <h1 className={classes.title}>
                        {productName}
                    </h1>
                  {/*{ring.isPriceLoading ? <div>Loading...</div> : <div className={classes.price}>${ToLocalStringConvert(+(diamond.details.b2c_price ? diamond.details.b2c_price : 0) + +ring.ringPrice)}</div>}*/}
                  {ring.isPriceLoading ? <div>Loading...</div> : <div className={classes.price}>${ToLocalStringConvert(+ringPrice)}</div>}

                </div>
                {ring.options.Ring_Side !== "PLN" &&
                <ul className={classes.subtitle}>
                    <li >Setting Total Weight: 0.25 ct.</li>
                    {ring.options["Wedding_Band"] !== weddingBand[0] && <li>{ring.options["Wedding_Band"] === weddingBand[1] ? 'Band' : 'Bands'} Total Weight: {ring.options["Wedding_Band"] === weddingBand[1] ? 0.25 : 0.50} ct.</li>}
                </ul>
                }
            </div>
            <div className={classes.details}>
                <p className={classes.desc}>{ring.extraData.Description}</p>
                {Object.entries(ring.optionsData).filter(([key,value]) =>!Object.keys(ring.selectedDiamondOptions).includes(key)).map(([key,value],index)=>{
                    if(key === 'Center_Diamond_Size') {
                        const marks : { [key: number]: string } = (value as DiamondSizeValueType[]).reduce((acc, val, index) => {
                            const step = 100 / ((value as DiamondSizeValueType[]).length - 1); // Calculate the step size
                            const key = step * index;
                            return { ...acc, [key]: val.Name };
                        }, {});
                        const fIndex = (value as any[]).map((v) => v.Code).indexOf(ring.options[key]);
                        return (
                            <div className={`${classes.items} hk_${key.toLowerCase()}`} key={index}>
                                <Label text={key.replaceAll('_',' ')} page={'pdpBuilder'}>
                                {/* shapeText={ring.options[key as keyof typeof ring.options]} */}
                                    <div className={classes.wrapper}>
                                        <DiamondSizeSlider
                                            marks={marks}
                                            handleAllOptions={handleAllOptions}
                                            shapeKey={key}
                                            fIndex={fIndex}
                                            allValue={value as centerDiamondSizeType[]}
                                        />
                                    </div>
                                </Label>
                            </div>
                        )
                    }else {
                        return (
                            <Fragment key={index}>{(Array.isArray(value) && value.length > 1) ?
                              <div className={`${classes.items} hk_${key.toLowerCase()} ${key.toLowerCase() === "color" ? `head_color` : '' }`}>
                                {/* shapeText={ring.options[key as keyof typeof ring.options]} */}
                                <Label text={key.replaceAll('_',' ')} page={'pdpBuilder'}>
                                    <div className={classes.wrapper}>
                                        {(value as any[]).map((v,index)=>{
                                            const isDiamondShape = DiamondShapeContent.find((shape)=>shape.code === v.Code);
                                            return(
                                                <Button
                                                    key={index}
                                                    isDisabled={!!Object.keys(ring.selectedDiamondOptions).find(diamond => diamond === key) || (ring.options && ring.options['Metal'] === 'PT') && (v.Code === "YG" || v.Code === "RG") }
                                                    buttonType={ring.options && ring.options[key as keyof typeof ring.options] === v.Code ? "active" : undefined}
                                                    type="button"
                                                    name={key}
                                                    code={v.Code}
                                                    className = {`${classes.button} hk_${key.toLowerCase()}_button`}
                                                    onClick={() => {
                                                        if (ring.options[key] !== v.Code) {
                                                            handleAllOptions(key, v);
                                                        }
                                                    }}
                                                >
                                                    {(v.image_name && !isDiamondShape) && (
                                                        <img
                                                            className={classes.swatchImg}
                                                            src={`${process.env.REACT_APP_PDP_IMAGE_KEY}${v.image_name}`}
                                                            alt={v.icon || v.Icon}
                                                        />
                                                    )}
                                                    {
                                                        isDiamondShape && (
                                                            <img
                                                                className={classes.swatchImg}
                                                                src={isDiamondShape.imgPath}
                                                                alt={v.icon || v.Icon}
                                                            />
                                                        )
                                                    }
                                                    {ColorImg[v.Name] && (
                                                        <img
                                                            className={`${classes.swatchImg} ${classes.swatchImgColor}`}
                                                            src={ColorImg[v.Name]}
                                                            alt={v.Name}
                                                        />
                                                    )}
                                                    <span className={classes.swatchText}>{v.Name}</span>
                                                </Button>
                                            )
                                        })}
                                    </div>
                                </Label>
                                {
                                    fieldValidation.length > 0 &&
                                        fieldValidation.includes(key)?
                                        (<span className={classes.error}><Icon name={'hk_info_circle'} />{`Please select your ${key.replace('_', ' ')}.`}</span>)
                                        : null
                                }
                            </div>: null}</Fragment>
                        )
                    }
                })}
                <div className={`${classes.items}`}>
                    <Label text={`Engraving`} optionsText={<em>(Complimentary)</em>}>
                        <FloatingLabel
                            label={"Your Message"}
                            labelId={`Engraving`}
                            maxLength={18}
                            keyName={"Engraving"}
                            setRingOptions={setRingOptions}
                            value={ring.options["Engraving"]}
                        />
                    </Label>
                </div>
                {ring.extraData.Bands ? <><div className={`${classes.items} band_options`}>
                    <Label text={"Matching Band"} optionsText={<span className={classes.smallChar}>(s)</span>} page={'pdpBuilder'}>
                        <div className={classes.wrapper}>
                            {weddingBand.map((band,index)=>{
                                return(
                                    <Button type={'button'} code={String(index)} key={index} buttonType={ring.options["Wedding_Band"] === band ? "active" : undefined} className={classes.button} onClick={()=>handleWeddingBand(index,band)}>
                                        <span className={classes.swatchText}>{band}</span>
                                    </Button>
                                )
                            })}
                        </div>
                    </Label>
                </div>
                {Array.from(Array(ring.tabIndex)).map((bandIndex,i) => {
                    return (
                        <React.Fragment key={i}>
                            <div className={`${classes.items} ${i === 0 ? 'left_band_color':'right_band_color'} hk_color`}>
                                <Label text={`${i === 0 ? 'First':'Second'} Band Color`} page={'pdpBuilder'}>
                                    <div className={classes.wrapper}>
                                        {weddingButton.map((band, index) => (
                                            <Button
                                                type={'button'}
                                                key={index}
                                                buttonType={ring.options[i === 0 ? `${ring.options["Wedding_Band"]}_First_Band`:`${ring.options["Wedding_Band"]}_Second_Band`] === band.Code ? "active" : undefined}
                                                isDisabled={ring.options && ring.options['Metal'] === 'PT' && ring.options['Color'] === "WG"  && band.Code !== ring.options['Color'] }
                                                className={`${classes.button} hk_color_button`}
                                                onClick={()=>handleMatchingBand(i,band.Code)}
                                            >
                                                <img className={`${classes.swatchImg} ${classes.swatchImgColor}`} src={ColorImg[band.Name]} alt={band.Name}/>
                                                <span className={classes.swatchText}>{band.Name}</span>
                                            </Button>
                                        ))}
                                    </div>
                                </Label>
                            </div>
                            <div className={`${classes.items}`}>
                                <Label text={`${i === 0 ? "First" : 'Second'} Band Engraving`} optionsText={<em>(Complimentary)</em>} page={'pdpBuilder'}>
                                    <FloatingLabel
                                        label={'Your Message'}
                                        labelId={`${ring.tabIndex}_${i}_Engraving`}
                                        maxLength={18}
                                        keyName={`${i === 0 ? "First" : 'Second'}_Band_Engraving`}
                                        setRingOptions={setRingOptions}
                                        value={ring.options[`${i === 0 ? "First" : 'Second'}_Band_Engraving`]}
                                    />
                                </Label>
                            </div>
                        </React.Fragment>
                    )
                })}
              </> : null}
            </div>
            <div className={classes.buttonWrap}>
                <Button type={"button"}
                        buttonType={"btnPrimary"}
                        className={classes.primaryButton}
                        onClick={handleSelectSetting}
                >
                    {/*<Icon name={"hk-viewDiamond"} />*/}
                    <span>Select This Setting</span>
                </Button>
                {Object.keys(diamond.details).length > 0 && !(rpid && uuid) && builder_mode !== "edit"  ? <Button type={'button'} className={classes.backButton}
                        onClick={() => {
                            // navigate(styleId?.includes("RP") ? -2 : -1);
                            (screenManager as any).changeScreen({
                                viewName: ring.journeyStartedFrom,
                                styleId:null,
                                id: ring.journeyStartedFrom === 'details'?(diamond.details.id):null,
                            })
                            // window.history.go(styleId?.includes("RP") ? -2 : -(ring.afterDiamondPageCount))
                            setBackDiamondDetails(true);
                            setHeaderTabDefault();
                            setInitialOption();
                            setIsShowFooter(false)
                        }}
                >
                <div className={classes.navigate}>
                    {/*<Icon name={"hk_chevron_left"} />*/}
                    <span>Back to Diamond Selection</span>
                </div>
            </Button> : null}
                {Object.keys(settingFilter.options).length > 0 && !(rpid && uuid) && builder_mode !== "edit" && !footer.isRedirectToEdit ?
                    (<Button type="button" className={classes.backButton} onClick={() => {
                        window.history.go(-1);
                        setInitialOption();
                    }}>
                        <span className={classes.navigate}>Back to Setting Selection</span>
                    </Button>) : null
                }
            </div>
        </div>
    </>
  )
}

export default connector(PdpBuilder);
