//@ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import classes from "./MainImageSlider.module.css";
import Slider from "react-slick";
import Icon from "../Icons";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Pagination } from 'swiper/modules';
// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import { MainImageSliderProps } from "../../types";
import useMainImageSlider from "./useMainImageSlider";
import {useMediaQuery} from "react-responsive";
import Loader from "../Loader/Loader";
import Button from "../Button";

const MainImageSlider: React.FC<MainImageSliderProps> = ({ img,isIcon,thumbImage,is360,dots=false ,viewSrc,iframeLoadActionCount,isLoading= false}) => {
    const {sliderForRef} = useMainImageSlider();


    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const ismidScreen = useMediaQuery({query: "(max-width: 1439px)"});
    const islargeTab = useMediaQuery({query: "(max-width: 1279px)"});
    const mainSliderRef = useRef(null);
    const [thumbsHeight, setThumbsHeight] = useState(0);
    const thumbsContainerRef = useRef(null);
    const [imageError, setImageError] = useState([]);
    const [handView,setHandView] = useState(false);
    const [skinView,setSkinView] = useState('light');
    const handleImageError = (index) => {
        setImageError(prevErrors => {
            const newErrors = [...prevErrors];
            newErrors[index] = true;
            return newErrors;
        });
    };
    const handleThumbsResize = () => {
        if (thumbsContainerRef.current) {
            setThumbsHeight(thumbsContainerRef.current.clientHeight);
        }
    };
    const onSwiperInit = (swiper) => {
        setThumbsSwiper(swiper);
        handleThumbsResize(); // This is triggered after Swiper is initialized
    };
    useEffect(() => {
        handleThumbsResize(); // Initialize thumbsHeight
        window.addEventListener("resize", handleThumbsResize);

        return () => {
            window.removeEventListener("resize", handleThumbsResize);
        };
    }, [onSwiperInit]);
    useEffect(() => {
        const rootStyle = (document.querySelector("#rootApp") as HTMLElement)?.style;

        if (rootStyle) {
            rootStyle.setProperty("--thumbHeight", ((thumbsHeight < 75) ? `${thumbsHeight}px` : '70px'));
        }
    },[thumbsHeight]);
    const handleHandView = () => {
        setHandView((toggle)=> {
            window.postMessage({action: 'ToggleHandView', value: !toggle})
            return !toggle;
        })
    }
    const skinSetting = ["light","dark","normal"]
    const handleSkinView = (val    ) => {
        setSkinView((skin)=> {
            window.postMessage({action: 'ToggleDarkHandSkin', value: val})
            return val;
        })
    }
    // if(isLoading) {
    //     return <Loader/>
    // }
    const sliderThumbImage = thumbImage ? thumbImage : img;
    const modules = [FreeMode, Navigation, Thumbs];

    if (dots) {
        modules.push(Pagination);
    }
    return (
            <div className={`sliderWrapper`}>
                {/* style={{ height: `${sliderHeight}px` }} */}
                {isLoading ?
                <Loader/> :<>
                <Swiper
                    loop={false}
                    onResize={handleThumbsResize}
                    pagination={{
                        clickable: true,
                        enabled : true,
                    }}
                    navigation={{ prevEl: ".arrow-left", nextEl: ".arrow-right" }}
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                    modules={modules}
                    onSwiper={(swiper) => (mainSliderRef.current = swiper)}
                    className={`${classes.swiperMain} mainSlider`}>
                    {
                        is360 && viewSrc ? <SwiperSlide>
                                    <div className={`${classes.iframe}`}>
                                        {/*<iframe src={"https://3dviewer.experience.jewelry"} className={classes.viewIframe}/>*/}
                                        <>
                                            <div className={classes.handViewConfig}>
                                                <div className={`${classes.skinToneWrap} ${handView ? classes.activeSkin : '' }`}>
                                                    <div className={classes.skinToneWrapInner}>
                                                        {
                                                            skinSetting.map((value, index)=>{
                                                                return(
                                                                    <button type={"button"} key={index} onClick={()=>handleSkinView(value)}
                                                                            className={
                                                                                `${value==="light" ? classes.skinLight : value==="dark" ? classes.skinDark : classes.skinNormal} ${value === skinView ? classes.active : ''}`
                                                                            }
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <Button type={"button"} className={`${handView ? classes.handView : classes.view360btn } ${classes.toggleViewbtn}`} onClick={handleHandView}>
                                                    Hand View
                                                    <span className={classes.toggleWrapper}>
                                                        <span className={classes.switchView}/>
                                                        <span className={classes.switchControl}/>
                                                    </span>
                                                </Button>
                                            </div>
                                            <iframe src={viewSrc} className={classes.viewIframe}/>
                                            <span className={classes.frameText}>Interactive Video – Drag to Rotate<Icon name={'hk-hand'} /></span>
                                        </>
                                        {iframeLoadActionCount > 0 && <Loader ajaxLoad={true}/>}
                                    </div>
                                </SwiperSlide> : null

                    }
                    { img.length > 0 ?
                        img.map((imgPath: string, index: number) => {
                        return (
                            <div key={index} className={classes.mainImages}>
                                {
                                    !imageError[index] ?<SwiperSlide key={index}>
                                        <img src={`${imgPath}?img=${index}`} />
                                    </SwiperSlide> : null
                                }
                            </div>
                        );
                    }) : null}
                    <div className={`${classes.buttonNav} ${!ismidScreen ? classes.disableButtons : ''}`}>
                        <button className="arrow-left arrow">
                            <Icon name={'hk-chevron-left_M'}/>
                        </button>
                        <button className="arrow-right arrow">
                            <Icon name={'hk-chevron-right_M'}/>
                        </button>
                    </div>
                </Swiper>
                <Swiper
                    onSwiper={setThumbsSwiper}
                    loop={false}
                    spaceBetween={10}
                    slidesPerView={7}
                    freeMode={false}
                    observer= {true}
                    centeredSlides={false}
                    observeParents= {true}
                    watchSlidesProgress={true}
                    modules={[Thumbs]}
                    className={`${classes.swiperThumb} swiperThumb ${islargeTab ? '!hidden' :'' }`}
                    // classes.disableThumbs
                    breakpoints = {{
                        0 : {
                            direction : 'horizontal',
                        },
                        1440 : {
                            direction : 'vertical'
                        },
                    }}
                >
                    {
                        is360 && viewSrc ? <SwiperSlide>
                            <div className={classes.icon360}>
                                <div className={classes.icon360Group}><Icon name="hk-rotate"/><span className={classes.text360Side}>360<sup>o</sup></span></div>
                                <span className={classes.text360}>Experience</span>
                            </div>
                        </SwiperSlide> : null
                    }
                    {sliderThumbImage.length > 0 ? sliderThumbImage.map((imgPath: string, index: number) => {
                        return (
                            <div
                                key={index}
                                className={classes.sliderThumbImages}
                                onClick={() => {
                                    sliderForRef.current?.slickGoTo(index);
                                }}
                            >
                                {
                                    !imageError[index] ?
                                        <SwiperSlide key={index} ref={thumbsContainerRef}>
                                            <img src={`${imgPath}?img=${index}`} alt={`Thumb Image-${index}`} className={imageError[index] ?`${classes.imgNotFound}` : ''} />
                                            <span className={imageError[index] ?`${classes.notFound}`:''}/>
                                        </SwiperSlide> : null
                                }
                                {/*<SwiperSlide key={index} ref={thumbsContainerRef}>
                                    <img src={`${imgPath}?img=${index}`} alt={`Thumb Image-${index}`} className={imageError[index] ? `${classes.imgNotFound}`:''} onError={() => handleImageError(index)} />
                                    <span className={imageError[index] ?`${classes.notFound}`:''}/>
                                </SwiperSlide>*/}
                            </div>
                        );
                    }) : null}
                </Swiper></>}
            </div>
    );
};

export default MainImageSlider
