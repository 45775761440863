import React, {FC, useEffect, useRef} from "react";
import classes from './diamondSizeSlider.module.css';
import useDiamondSizeSlider from "./useDiamondSizeSlider";
import Slider from "rc-slider";
import {DiamondSizeSliderType} from "../../types";

const DiamondSizeSlider :FC<DiamondSizeSliderType> = (props) => {
    const {marks,handleAllOptions,shapeKey,fIndex,allValue,isStartWithSetting,isReset} = props;
    const {pRef,value,handleChange,handleLabelChangeComplete} = useDiamondSizeSlider({marks,handleAllOptions,shapeKey,fIndex,allValue,isStartWithSetting,isReset});

  /**
   const diamSizeEvent = new CustomEvent('setDiamondSize', { detail: { size: '150' } });
   document.querySelector('.diamond_size').dispatchEvent(diamSizeEvent)
   *
   *
   * ref.addEventListener('setDiamondSize', function(e) {
   *   console.log('setDiamondSize event on sizer', e.detail)
   * })
   */

  return (
        <div className={`${classes.root} diamond_size`} ref={pRef}>
            <Slider
                onChangeComplete={handleLabelChangeComplete}
                dots
                range
                min={+Object.keys(marks)[0]}
                max={+Object.keys(marks)[Object.keys(marks).length - 1]}
                marks={marks}
                step={null}
                defaultValue={[
                    +Object.keys(marks)[fIndex],
                ]}
                value={value}
                onChange={handleChange}
                allowCross={false}
                pushable
                draggableTrack={false}
                handleStyle={{borderColor: "#4a4a4a", backgroundColor: "#fff"}}
                trackStyle={{backgroundColor: "#4a4a4a"}}
                railStyle={{backgroundColor: "rgb(74, 74, 74)"}}
                dotStyle={{border: "none"}}
            />
        </div>
    )
}
export default DiamondSizeSlider;
